import React from "react";

export const Enroll = (props) => {
  return (
    <div id="enroll" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>How much does this cost?</h2>
          <br/>
          <p>
            Remember: this is an <b>investment</b> in yourself.<br/>
            <br/>
            Refusing it can cost you <b>valuable time</b> to achieve the dance level that you desire.<br/>
            Join now this process that can bring you <spam className="gradient-text">happiness, health, new friends along the way</spam>, and much more.<br/>
            <br/>
            Don't miss out on this opportunity!
          </p>
          <br/>
          <div className="offer-block col-md-8 col-md-offset-2">
            <h2 className="gradient-text">The Forró Community</h2>
            <p className="big">29$/mo</p>
            <div className="list-style">
              <ul>
                <li>
                  <b>Virtual Classrooms</b> -
                  Starting with 15 bite-size lessons (5 for each of the 3 levels). More to be uploaded regularly.
                </li>
                <li>
                  <b>Questions & Answers</b> -
                  Share your dance questions in the community and I will answer them to the best of my knowledge.
                </li>
                <li>
                  <b>Open discussion channels</b> -
                  We can talk about various forró & dance topics, not only technical, but also personal, social, etc.
                </li>
                <li>
                  <b>BONUS</b> -
                  Send me a dance video of yours per Telegram and get a private voice reply with personalised tips!
                </li>
              </ul>
            </div>

            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.skool.com/forro-community-2221/about"
                className="btn btn-custom btn-lg page-scroll"
              >
                Enroll Now*
              </a>{" "}
              <p className="small">
                *This button takes you to the checkout page on our platform: Skool.<br/>
                Finish your registration there and get instant access to our community.<br/>
                <b className="gradient-text">See you inside ;)</b>
              </p>
            </div>

            <div className="bonus-disclaimer">
              <hr class="solid"></hr>
              <h5>Enroll now to get the following extra bonuses*:</h5>
              <p className="small">*You must finish your registration in <b>May/2024</b>, bonus must be redeemed in June/2024.</p>
            </div>
            <div className="list-style">
              <ul>
                <li>
                  <b style={{"color":"red"}}>Extra Bonus 1</b> -
                  Get personalised tips for 2 extra dance videos.
                </li>
                <li>
                  <b style={{"color":"red"}}>Extra Bonus 2</b> -
                  Get a <b>30min online private session</b> with me!
                  <p className="small">Let's meet in a video call. Share with me what you're currently living in dance and get advice.<br/>
                  (It can be more technical, like learn a move or a skill; or whatever dance advice you may want).</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
