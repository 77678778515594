import React from "react";

export const MyJourney = (props) => {
  return (
    <div id="myjourney">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title text-center">
          <h2>
            Learning forró can completely <spam className="gradient-underline">change your life</spam><br/>
            - it changed mine.
          </h2>
        </div>
        <div className="col-md-8 col-md-offset-2">
          <p>If we haven't met before - Hi, my name's Matheus 👋</p>
          <p>I started dancing forró in 2012, teaching in 2016, travelling in 2018, my own studio in 2022.</p>
          <p>Back in the days when I was learning my first steps in BH-Brazil, I remember <b className="gradient-text">how hard each simple move was for me</b>. I mean it and I have videos to prove 😂</p>
          <p>Still, I was amazed by how much joy one could have learning it. Bad thing <b>I had to commute for so long</b> to get to the school...</p>
          <p>Until they finally opened one school near me! It got way easier to go to classes, but... They took place only once a week, and eventually I would <b>miss a class</b> for whatever reason, resulting in <b>13 days gap between 2 classes</b>, and me forgetting things...</p>
          <p>By that time,<spam className="gradient-background"> I wished I had a better solution to learn...</spam></p>
          <p>Only 2 years later, my life context changed and I was able to commute more and even travel on a weekly basis to classes in other cities. That's when my virtuous cycle started.</p>
          <p className="big">
            I was <b>dancing better</b>, thus <b>feeling better</b> dancing,<br/>
            <spam style={{"font-size":"26"}}>going out (& travelling) more to dance, meeting more fellow dancers who eventually became close friends!</spam>
          </p>
          <p>Never stopped. Eventually started teaching. Back then, still lacking some <b>important dance knowledge & skills</b>...</p>
          <p><u>The pandemic hits in 2020</u>. Some top-level ballroom dancers started <b className="gradient-text">online courses</b>. I took many of them with the main goal of improving my teaching skills. But I ended up achieving more than that...</p>
          <p>I was learning fundamentals of dance, useful exercises, and much more that led me to <b className="gradient-text">improve my dance, alone, and without leaving home!</b></p> 
          <p>Next time I went dancing, I could feel my improvement and it felt awesome. That was the begin of a new virtuos cycle, again considerably <b className="gradient-background">changing my life for better.</b></p>
          <p>Sounds interesting? - Then good news: we started a digital Forró Community to make that possible to you NOW, <b>regardless of your dance level!</b> Here is how...</p>
        </div>
      </div>
    </div>
  );
};
