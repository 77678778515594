import React from "react";

export const MyHeader = (props) => {
  return (
    <div id="myHeader" className="text-center">
      <div className="container">
        <div className="col-md-12 section-title">
          <h2>
            Welcome to the
            <br/>
            <spam className="gradient-text">Forró Community</spam>
          </h2>
          <br/>
          <h1>
            We'll help you develop <spam className="gradient-underline">dance skills</spam>.<br/>
            And you will <spam className="gradient-underline">enjoy</spam> even more the dance floor.
          </h1>
          <br/>
          <p className="big">
            Discover new <spam className="gradient-background">forró moves</spam> of various styles -<br/>
            <spam className="gradient-text">without commuting to the studio.</spam>
          </p>
          <a
              href="#enroll"
              className="btn btn-custom btn-lg page-scroll"
            >
              I'm ready to join!
          </a>{" "}
        </div>
      </div>
    </div>
  );
};
