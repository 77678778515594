import React from "react";

export const MemberJourney = (props) => {
  return (
    <div id="memberJourney">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title text-center">
          {/* <h5>What's inside</h5> */}
          <h2>
            Become more <spam className="gradient-underline">confident</spam> and have more <spam className="gradient-underline">fun</spam> <br/>
            on the dance floor
          </h2>
        </div>
        <div className="col-md-8 col-md-offset-2">
          <h3>1) Learn new steps & skills according to your level</h3>
          <p>Bite-size lessons for beginners, intermediate and advanced dancers.</p>
          <h3>2) Interact and grow together with the community</h3>
          <p>
            Right now we are still a small community. Enjoy close interactions with fellow dancers, exchange your process, challenges and wins. We also have private chats for that.
          </p>
          <h3>3) Professional support on demand</h3>
          <p>
            I will always be an active members in the community. Whenever you have questions about dance, post them in the open channels and I will answer to the best of my knowledge.
          </p>
        </div>
      </div>
    </div>
  );
};
